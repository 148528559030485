<template lang="pug">
.main-wrapper.pessoa-juridica-salvar
    ProgressBar(v-if='waiting' mode="indeterminate")
    div(v-else)
        header.main-wrapper-header
            .p-grid.p-align-center
                .p-col-3
                    Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
                .p-col-9.ta-right
                    h1.text-header.text-secondary MedClub /
                        <router-link :to='{ name: "pessoa-juridica" }'> Pessoa Jurídica</router-link> /
                        <b>{{ model.id ? 'Editar' : 'Adicionar' }}</b>

        form(@submit.prevent='handleSubmit()')
            ProgressBar(v-if='waitingForm || waiting' mode="indeterminate")
            div(v-else)
                Panel(:header="`${ model.id ? 'Editar' : 'Adicionar' } Pessoa Jurídica`")
                    .p-grid.p-fluid.p-align-end
                        .p-col-12.p-md-2
                            ProgressBar(v-if="waitingTipo" mode="indeterminate")
                            div(v-else)
                                label.form-label Tipo:
                                Dropdown(
                                    v-model='model.ie_tipo_pessoa_juridica'
                                    :options='options.tipos'
                                    optionLabel='ds_valor'
                                    optionValue='ie_valor'
                                    placeholder='Selecione'
                                )
                        .p-col-12.p-md-2(:class="{ 'form-group--error': submitted && $v.model.nr_cnpj.$error }")
                            label.form-label CNPJ:
                            InputMask(mask='99.999.999/9999-99' v-model='$v.model.nr_cnpj.$model')
                            .feedback--errors(v-if='submitted && $v.model.nr_cnpj.$error')
                                .form-message--error(v-if="!$v.model.nr_cnpj.minLength") <b>CNPJ</b> deve ter pelo menos 2 caracteres.
                                .form-message--error(v-if="!$v.model.nr_cnpj.required") <b>CNPJ</b> é obrigatório.

                        .p-col-12.p-md-4(:class="{ 'form-group--error': submitted && $v.model.ds_razao_social.$error }")
                            label.form-label Razão Social:
                            InputText(type='text' v-model='$v.model.ds_razao_social.$model')
                            .feedback--errors(v-if='submitted && $v.model.ds_razao_social.$error')
                                .form-message--error(v-if="!$v.model.ds_razao_social.minLength") <b>Razão Social</b> deve ter pelo menos 2 caracteres.
                                .form-message--error(v-if="!$v.model.ds_razao_social.required") <b>Razão Social</b> é obrigatório.


                        .p-col-12.p-md-4(:class="{ 'form-group--error': submitted && $v.model.nm_fantasia.$error }")
                            label.form-label Nome fantasia:
                            InputText(type='text' v-model='$v.model.nm_fantasia.$model')
                            .feedback--errors(v-if='submitted && $v.model.nm_fantasia.$error')
                                .form-message--error(v-if="!$v.model.nm_fantasia.minLength") <b>Nome Fantasia</b> deve ter pelo menos 2 caracteres.
                                .form-message--error(v-if="!$v.model.nm_fantasia.required") <b>Nome Fantasia</b> é obrigatório.

                        .p-col-12.p-md-2
                            label.form-label IE:
                            InputText(type='text' v-model='model.nr_inscricao_estadual')

                        .p-col-12.p-md-2
                            label.form-label IM:
                            InputText(type='text' v-model='model.nr_inscricao_municipal')

                        .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.planos_contas.$error }")
                            ProgressBar(v-if="waitingPlanoContas" mode="indeterminate")
                            div(v-else)
                                label.form-label Conta Financeira:
                                MultiSelect(
                                    :options='options.contasFinanceiras'
                                    placeholder='Selecione'
                                    optionLabel='ds_descricao'
                                    dataKey='id'
                                    :filter='true'
                                    v-model='model.planos_contas'
                                )
                            .feedback--errors(v-if='submitted && $v.model.planos_contas.$error')
                                .form-message--error(v-if="!$v.model.planos_contas.required") <b>Plano Contas</b> é obrigatório.

                        .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.cnaes.$error }")
                            ProgressBar(v-if="waitingCnae" mode="indeterminate")
                            div(v-else)
                                label.form-label Código CNAE:
                                MultiSelect(
                                    :options='options.cnaes'
                                    placeholder='Selecione'
                                    optionLabel='ds_descricao'
                                    dataKey='cod_cnae'
                                    :filter='true'
                                    v-model='model.cnaes'
                                )
                            .feedback--errors(v-if='submitted && $v.model.cnaes.$error')
                                .form-message--error(v-if="!$v.model.cnaes.required") <b>CNAE</b> é obrigatório.

                        .p-col-12.p-md-2
                            label.form-label Emitir NF Automaticamente:
                            Checkbox(v-model='model.ie_nf_automatica' :binary="true")

                    br
                    Panel(header="Endereço")
                        .feedback--errors(v-if='submitted && $v.model.enderecos.$error')
                            .form-message--error(v-if="!$v.model.enderecos.required") <b>Endereço</b> é obrigatório.
                        br
                        Endereco(:parentData="model.enderecos[0]" :pessoaJuridicaId="id" @enderecoEmitido="handleEndereco" ref="enderecoComponent")
                    br
                    Panel(header="Contatos")
                        Contato(:parentData="model.contatos" :pessoaJuridicaId="id" @contatosEmitido="handleContatos" ref="contatoComponent")
                    br
                    Panel(header="Dados Bancários")
                        .feedback--errors(v-if='submitted && $v.model.bancos.$error')
                            .form-message--error(v-if="!$v.model.bancos.required") <b>Ao menos um banco</b> é obrigatório.
                            br
                        DadosBancarios(:parentData="model.bancos" :pessoaJuridicaId="id" @bancosEmitido="handleBancos" ref="dadosBancariosComponent")
                    br
                    Panel(header="Impostos")
                        Impostos(:parentData="model.tributos" @tributosEmitidos="handleTributos" ref="tributosComponent")

                    .p-grid.p-fluid.p-align-end
                        .p-col-12.text-area
                            label.form-label Observação:
                            Textarea(type='text' v-model='model.ds_observacao' rows="3" variant="filled" autoResize style="width: 100%")

                            .p-col-12.ta-right
                            Button(
                                label='Salvar'
                                icon='jam jam-check'
                                type="submit"
                                style='max-width:160px'
                            )

</template>

<style lang="scss">
.text-area {
    width: 100%;
}

.empresa-salvar {

    .waitingEmpresas-wrapper {
        text-align: center;
        padding: 80px 0;

        .p-progress-spinner {
            width: 60px;
            height: auto;
        }
    }

    .aux-1 {
        text-align: center;

        @media all and (max-width: 576px) {
            text-align: left;
        }
    }

    .p-inputtext[disabled=disabled] {
        color: #000;
        font-weight: 600;
        background-color: #ddd;
    }

    .p-picklist-source-controls,
    .p-picklist-target-controls {
        display: none;
    }

    .p-picklist-source-wrapper,
    .p-picklist-target-wrapper {
        width: 45%;

        @media all and (max-width: 576px) {
            width: 100%;
        }
    }

    .p-picklist-buttons-cell {
        text-align: center;

        .p-button {
            display: none !important;

            &:first-of-type,
            &:nth-of-type(3) {
                display: inline-block !important;
            }
        }
    }

    .text-box {
        margin: 0;
        font-size: 16px;
    }

    .input-sexo .p-button {
        width: 50% !important;
    }

    .waiting-status {
        width: 25px;
        height: auto;
    }

    .status-indicator {
        display: inline-block;
        font-size: 0;
        width: 23px;
        height: 23px;
        line-height: 36px;
        border-radius: 50%;
        background-color: #94c860;
        border: 1px solid #84b553;
        transition: 250ms;
        cursor: pointer;
        padding-right: 10px;

        &:hover {
            background-color: #84b553;
        }

        &.off {
            background-color: #d33838;
            border: 1px solid #b33030;

            &:hover {
                background-color: #b33030;
            }
        }

        .jam {
            color: #fff;
            margin-left: 2px;
        }
    }
}
</style>

<script>
import ProgressBar from 'primevue/progressbar'
import InputText from 'primevue/inputtext'
import InputMask from 'primevue/inputmask'
import Button from 'primevue/button'
import Dropdown from 'primevue/dropdown'
import Tooltip from 'primevue/tooltip'
import Dialog from 'primevue/dialog'
import { required, minLength } from 'vuelidate/lib/validators'
import Contato from './Components/Contato.vue'
import Endereco from './Components/Endereco.vue'
import DadosBancarios from './Components/DadosBancarios.vue'
import Impostos from './Components/Impostos.vue'
import Panel from 'primevue/panel'
import MultiSelect from 'primevue/multiselect'
import Checkbox from 'primevue/checkbox'

import { PessoaJuridica, DominioValor, PlanoContas } from '../../middleware'

const _ = require('lodash')

export default {
    components: {
        ProgressBar, InputText,
        Button, Impostos, InputMask, Dropdown,
        Tooltip, Dialog, Contato, MultiSelect,
        Endereco, DadosBancarios, Panel, Checkbox
    },
    directives: { tooltip: Tooltip },
    created() {
        this.id = parseInt(this.$route.params.id)
        if (this.id != 0) {
            this.waiting = true
            PessoaJuridica.find(this.id).then(response => {
                if (response.status === 200) {
                    this.model = response.data
                    this.getTipos()
                    this.getPlanoContas()
                    this.getCnaes()
                }
                this.waiting = false
            })
        } else {
            this.getTipos()
            this.getPlanoContas()
            this.getCnaes()
        }
    },
    data() {
        return {
            model: {
                nr_cnpj: null,
                ds_razao_social: null,
                nm_fantasia: null,
                nr_inscricao_estadual: null,
                nr_inscricao_municipal: null,
                ie_tipo_pessoa_juridica: null,
                ie_nf_automatica: false,
                enderecos: [],
                bancos: [],
                contatos: [],
                planos_contas: [],
                cnaes: [],
                tributos: [],
                ds_observacao: null,
                nm_usuario_cri: null,
                nm_usuario_edi: null,
                dt_atualizacao: null,
            },
            options: {
                tipos: [],
                contasFinanceiras: [],
                cnaes: []
            },
            filters: {
                ie_tipo_pessoa_juridica: [],
                planos_contas: []
            },
            id: 0,
            waiting: false,
            waitingForm: false,
            waitingTipo: false,
            waitingPlanoContas: false,
            waitingCnae: false,
            submitted: false,
            params: {},
        }
    },
    validations() {
        let v = {
            model: {
                ds_razao_social: { required, minLength: minLength(2) },
                nm_fantasia: { required, minLength: minLength(2) },
                nr_cnpj: { required, minLength: minLength(2) },
                enderecos: { required },
                bancos: { required },
                planos_contas: { required },
                cnaes: { required },

            }
        }

        return v
    },
    methods: {
        getTipos() {
            this.waitingTipo = true
            DominioValor.findAll({ ds_mnemonico: 'TIPO_PESSOA_JURIDICA' }).then((response) => {
                if (response.status === 200) {
                    this.options.tipos = response.data['TIPO_PESSOA_JURIDICA'].valores
                }
                this.waitingTipo = false
            })
        },
        getPlanoContas() {
            this.waitingPlanoContas = true
            PlanoContas.findAll().then(response => {
                if ([200, 201].includes(response.status)) {
                    this.options.contasFinanceiras = response.data
                    let new_plano_contas = []
                    if (this.model.planos_contas) {
                        this.model.planos_contas.forEach(conta => {
                            for (let option of this.options.contasFinanceiras) {
                                if (conta.cd_plano_contas && conta.cd_plano_contas == option.id) {
                                    new_plano_contas.push(option)
                                    this.model.planos_contas = new_plano_contas
                                    break
                                }
                            }
                        })
                    }
                }
                this.waitingPlanoContas = false
            })
        },
        getCnaes() {
            this.waitingCnae = true
            PessoaJuridica.findAllCnae().then(response => {
                if ([200, 201].includes(response.status)) {
                    this.options.cnaes = response.data.map(cnae => {
                        return {
                            ds_descricao: cnae.ds_descricao,
                            cod_cnae: cnae.id
                        }
                    })

                    let new_cnae = []
                    if (this.model.cnaes) {
                        this.model.cnaes.forEach(cnae => {
                            for (let option of this.options.cnaes) {
                                if (cnae.cod_cnae && cnae.cod_cnae == option.cod_cnae) {
                                    new_cnae.push(option)
                                    this.model.cnaes = new_cnae
                                    break
                                }
                            }
                        })
                    }
                }
                this.waitingCnae = false
            })
        },
        onpenDialogUsuario(model) {
            this.usuario.model = Object.assign({}, model)
            this.usuario.dialog = true
        },
        handleEndereco(endereco) {
            this.model.enderecos = endereco
        },
        handleContatos(contatos) {
            this.model.contatos = contatos
        },
        handleBancos(bancos) {
            this.model.bancos = bancos
            this.$forceUpdate()
        },
        handleTributos(tributos) {
            this.model.tributos = tributos
        },
        handleSubmit() {
            this.submitted = true

            this.$v.$touch()
            if (this.$v.$invalid) return 0

            const dados = _.cloneDeep(this.model)
            dados.nr_cnpj = dados.nr_cnpj.replace(/\D/g, "")

            if (this.id) {
                delete dados.bancos
                delete dados.enderecos
                delete dados.contatos

                dados.lista_planos_contas = dados.planos_contas.map(element => element.id)
                delete dados.planos_contas
            } else {
                dados.bancos.map(element => {
                    element.cd_banco = element.cd_banco.id
                    element.ds_tipo_pix = element.ds_tipo_pix.value
                    return element
                })
                dados.planos_contas = dados.planos_contas.map(element => element.id)
            }
            dados.lista_cnae = dados.cnaes.map(cnae => cnae.cod_cnae)
            dados.lista_tributos = dados.tributos
            this.waiting = true
            PessoaJuridica.save(dados).then(response => {
                if ([200, 201].includes(response.status)) {
                    if (!dados.id) this.$router.push({ name: 'pessoa-juridica' })
                    else {
                        this.getTipos()
                        this.getPlanoContas()
                        this.getCnaes()
                    }
                    this.model = response.data
                    this.$toast.success('Pessoa Jurídica salva com sucesso', { duration: 3000 })
                } else {
                    for (let error in response.data) {
                        this.$toast.error(response.data[error].join(', '), { duration: 3000 })
                    }
                }
                this.waiting = false
                this.$v.$reset()
            })
        }

    }
}
</script>
